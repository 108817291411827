//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { GRE_TOPICS } from '~/static/constants.js';
import { guideIcon, greBlogIcon, gmatBlogIcon, freebiesIcon } from '~/static/icons/index';
import { ROUTES } from '~/constants/pageRoutes';

const RESOURCE_TYPE = {
    FREEBIES: 1,
    GUIDE: 2,
    GRE_BLOG: 3,
    GMAT_BLOG: 4
};

export default {
    name: 'ResourceSelector',
    props: {
        windowWidth: { type: Number, default: 0 }
    },
    data() {
        return {
            // Data
            GRE_TOPICS,
            RESOURCE_TYPE,
            sections: [
                {
                    id: RESOURCE_TYPE.GUIDE,
                    title: 'GRE Guide',
                    subtitle: 'A GRE-Specific library of information',
                    icon: guideIcon,
                    redirection: ROUTES.BLOGS_AND_GUIDES,
                    eventFor: 'guide'
                },
                {
                    id: RESOURCE_TYPE.GRE_BLOG,
                    title: 'GRE Blogs',
                    subtitle: 'Expert-curated GRE Tips and Tricks',
                    icon: greBlogIcon,
                    redirection: ROUTES.GRE_BLOG_LISTING,
                    eventFor: 'gre-blogs'
                },
                {
                    id: RESOURCE_TYPE.GMAT_BLOG,
                    title: 'GMAT Blogs',
                    subtitle: 'Expert-curated GMAT Tips and Tricks',
                    icon: gmatBlogIcon,
                    redirection: ROUTES.GMAT_BLOG_LISTING,
                    eventFor: 'gmat-blogs',
                    showNewTag: true
                }
            ],
            activeSection: {},
            showList: true
        };
    },
    computed: {
        ...mapGetters({
            hasPrepFreebies: 'auth/hasPrepFreebies',
            isFreeTrialExpired: 'auth/isFreeTrialExpired',
            isPremiumUser: 'auth/isPremiumUser'
        })
    },
    mounted() {
        // Condition to show freebies tab
        if (!this.isPremiumUser && this.hasPrepFreebies && !this.isFreeTrialExpired) {
            this.sections.unshift({
                id: RESOURCE_TYPE.FREEBIES,
                title: 'Your Freebies',
                subtitle: 'Cheatsheet formula and Videos',
                icon: freebiesIcon,
                redirection: ROUTES.FREEBIES,
                eventFor: null
            });
        }
    },

    methods: {
        redirect(value) {
            if (value.external) {
                window.open(value.redirection, '_blank');
            } else {
                this.$router.push(`${value.redirection}`);
            }

            if (value.eventFor) {
                this.emitEvent(value.eventFor);
            }
        },
        emitEvent(event, payload = null) {
            const payloadObjToEmit = {
                eventFor: event
            };

            if (payload) {
                payloadObjToEmit.resource = payload;
            }

            this.$emit('fireEvent', payloadObjToEmit);
        },
        toggleSubList(value) {
            if (value === RESOURCE_TYPE.GUIDE) {
                this.showList = true;
            } else {
                this.showList = false;
            }
        }
    }
};
